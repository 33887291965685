<template>
  <div>
    <page-title
      :heading="$t('kiosk.lang_kiosk_design_config')"
      :subheading="$t('kiosk.lang_kiosk_design_config')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <kiosk-products-management-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import KioskProductsManagementComponent from "@/components/kiosk/products_management/KioskProductsManagementComponent";

export default {
  name: "KioskProductsView",
  components: {
    KioskProductsManagementComponent,
    PageTitle,
  },

  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
