<template>
  <div>
    <v-tabs v-model="currentTab">
      <v-tab key="categories">
        {{ this.$t('erp.lang_nav_waregroups') }}
      </v-tab>
      <v-tab key="products">
        {{ this.$t('erp.lang_items') }}
      </v-tab>

      <v-tabs-items v-model="currentTab">
        <v-tab-item key="categories">
          <kiosk-categories-component ref="categories" />
        </v-tab-item>
        <v-tab-item key="products">
          <kiosk-products-component ref="products" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import KioskCategoriesComponent from "@/components/kiosk/products_management/KioskCategoriesComponent";
import KioskProductsComponent from "@/components/kiosk/products_management/KioskProductsComponent";

export default {
  name: "KioskProductsManagementComponent",
  components: {KioskProductsComponent, KioskCategoriesComponent},
  data() {
    return {
      currentTab: "categories",
      mounted: false,
    }
  },
  watch: {
    currentTab(v){
      switch(v){
        case 0:
          this.$refs.categories?.getDataFromApi()
          break;
        case 1:
          this.$refs.products?.getDataFromApi()
          break;
      }
    }
  }
}
</script>
